export const routeArray = [
  "/",
  "/about",
  "/about/",
  "/blogs",
  "/blogs/",
  "/blogs/:slug/",
  "/contact",
  "/contact/",
];

import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { checkIfBlog } from "../../utils/helperFuntions";

import "./header.scss";

const Header = ({ error, pathName }) => {
  const toggleColorMode = (event) => {
    if (event.currentTarget.classList.contains("light--hidden")) {
      document.documentElement.setAttribute("color-mode", "light");
      localStorage.setItem("color-mode", "light");
      return;
    }
    document.documentElement.setAttribute("color-mode", "dark");
    localStorage.setItem("color-mode", "dark");
  };

  const toggleSelection = (event) => {
    Array.prototype.slice
      .call(document.getElementsByClassName("header--navigation__link"))
      .forEach((item) => {
        item.classList.remove("selected");
      });

    document
      .getElementById(
        event.target.id === "nav-brand" ? "nav-home" : event.target.id
      )
      .classList.add("selected");
  };

  return (
    <nav className={!error ? "header--navigation error" : "header--navigation"}>
      <div className="header--navigation__container">
        <h3 className="header--navigation__brand" onClick={toggleSelection}>
          <Link to="/" id="nav-brand">
            Rajan Mali
          </Link>
        </h3>
        <ul className="header--navigation__list">
          <li className="header--navigation__item" onClick={toggleSelection}>
            <Link
              to="/"
              className={
                !error
                  ? "header--navigation__link"
                  : pathName === "/"
                  ? "header--navigation__link selected"
                  : "header--navigation__link"
              }
              id="nav-home"
            >
              Home
            </Link>
          </li>
          <li className="header--navigation__item" onClick={toggleSelection}>
            <Link
              to="/about"
              className={
                pathName === "/about"
                  ? "header--navigation__link selected"
                  : "header--navigation__link"
              }
              id="nav-about"
            >
              About
            </Link>
          </li>
          <li className="header--navigation__item" onClick={toggleSelection}>
            <Link
              to="/blogs"
              className={
                checkIfBlog(pathName) === "/blogs/:slug/" ||
                checkIfBlog(pathName) === "/blogs"
                  ? "header--navigation__link selected"
                  : "header--navigation__link"
              }
              id="nav-blogs"
            >
              Blogs
            </Link>
          </li>
          <li className="header--navigation__item" onClick={toggleSelection}>
            <Link
              to="/contact"
              className={
                pathName === "/contact"
                  ? "header--navigation__link selected"
                  : "header--navigation__link"
              }
              id="nav-contact"
            >
              Contact
            </Link>
          </li>
          {/* <div className="selected-indicator" /> */}
          <div className="header--color-toggle__container">
            <button
              className="color-mode__btn light--hidden"
              onClick={toggleColorMode}
            >
              <svg aria-hidden="true">
                <use href="#color-mode-toggle-icon"></use>
              </svg>
            </button>
            <button
              className="color-mode__btn dark--hidden"
              onClick={toggleColorMode}
            >
              <svg aria-hidden="true">
                <use href="#color-mode-toggle-icon"></use>
              </svg>
            </button>
          </div>
        </ul>
      </div>
      <svg style={{ display: "none" }}>
        <symbol viewBox="0 0 24 24" id="color-mode-toggle-icon">
          <path
            id="Exclusion_3"
            data-name="Exclusion 3"
            d="M11,22h0A11,11,0,0,1,3.222,3.222,10.925,10.925,0,0,1,11,0h.073a11,11,0,0,1,7.7,18.778A10.885,10.885,0,0,1,12.6,21.884,11.142,11.142,0,0,1,11,22ZM11,2a9,9,0,0,0,0,18V16h0A5,5,0,0,1,11,6V16A5,5,0,1,0,11,6Z"
          />
        </symbol>
      </svg>
    </nav>
  );
};

Header.propTypes = {
  error: PropTypes.bool,
  pathName: PropTypes.string,
};

export default Header;

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from "../HomePage/Loadable";
import AboutUsPage from "../About/Loadable";
import BlogPage from "../Blogs/Loadable";
import BlogDetailsPage from "../Blogs/View/Loadable";
import ContactPage from "../Contact/Loadable";
import NotFoundPage from "../NotFoundPage/Loadable";

const ApplicationRoutes = ({ location }) => (
  <Switch location={location}>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/about" component={AboutUsPage} />
    <Route exact path="/blogs" component={BlogPage} />
    <Route exact path="/blogs/:slug" component={BlogDetailsPage} />
    <Route exact path="/contact" component={ContactPage} />
    <Route exact path="/not-found" component={NotFoundPage} />
    <Redirect to="/not-found" />
  </Switch>
);

export default ApplicationRoutes;

import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import ApplicationRoutes from "./routes";

import { checkIfBlog } from "../../utils/helperFuntions";
import { routeArray } from "../../utils/constants";

import Header from "../../components/Header/index.jsx";
import Footer from "../../components/Footer/index.jsx";
import "./app.scss";

const App = () => {
  const routeValidityChecker = (pathName) => {
    return routeArray.includes(checkIfBlog(pathName));
  };

  return (
    <Router>
      <Route
        render={({ location }) => (
          <div
            className={
              routeValidityChecker(location.pathname) ? "app" : "app error"
            }
          >
            <Header
              error={routeValidityChecker(location.pathname)}
              pathName={location.pathname}
            />
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={250} classNames="fade">
                <div className="wrapper">
                  <ApplicationRoutes location={location} />
                </div>
              </CSSTransition>
            </TransitionGroup>
            <Footer error={routeValidityChecker(location.pathname)} />
          </div>
        )}
      />
    </Router>
  );
};

export default App;

import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div>Rajan Mali . 2020</div>
        <div className="social-media-container">
          <a
            href="https://twitter.com/notrajanmali"
            target="_blank"
            rel="noopener noreferrer"
            className="social-media-link"
          >
            Twi
          </a>
          .
          <a
            href="https://www.linkedin.com/in/rajan-mali-40516516b/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-media-link"
          >
            Linkd
          </a>
          .
          <a
            href="https://www.instagram.com/notrajanmali/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-media-link"
          >
            Insta
          </a>
          .
          <a
            href="https://twitter.com/notrajanmali"
            target="_blank"
            rel="noopener noreferrer"
            className="social-media-link"
          >
            Dribb
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
